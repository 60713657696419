import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useMyGlobal } from "../../component/Global";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Stack from "react-bootstrap/Stack"
import { Button, Dimmer, Form, FormCheckbox, FormField, FormGroup, FormInput, FormSelect, Icon, Input, Loader, Popup, TextArea } from "semantic-ui-react";
import Modal from "rsuite/Modal";
import { curencyNoSym, currentDate } from "../../component/utils";
import { Toaster } from "../../component/Toaster";
import { Reacttable } from "../../component/Reacttable";
import DateRangePicker from 'rsuite/DateRangePicker';
import moment from "moment";


export const SalesOrder = () => {
    const [modalCreate, setModalCreate] = useState(false)
    const ppn = 12
    const { host, userProfile, cityOpt, provOpt, warehouseOpt } = useMyGlobal()
    const [customerOpt, setCustomerOpt] = useState([])
    const [salesOpt, setSalesOpt] = useState([])
    const [usePpn, setUsePpn] = useState(true)
    const [useFaktur, setUseFaktur] = useState(false)
    const [deliveryOpt, setDeliveryOpt] = useState([])
    const [date, setDate] = useState(currentDate)
    const [reference, setReference] = useState('')
    const [customerName, setCustomerName] = useState('')
    const [dataCustomer, setDataCustomer] = useState([])
    const [salesName, setSalesName] = useState('')
    const [delivery, setDelivery] = useState('')
    const [deliveryDetail, setDeliveryDetail] = useState('')
    const [modalProduct, setModalProduct] = useState(false)
    const [dataProduct, setDataProduct] = useState([])
    const [warehouse, setWarehouse] = useState('')
    const [loadProduct, setLoadProduct] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState([])
    const [selectedQty, setSelectedQty] = useState(1)
    const [selectedRow, setSelectedRow] = useState(null)
    const [modalParam, setModalParam] = useState(false)
    const [modalEditQty, setModalEditQty] = useState(false)
    const [top, setTop] = useState(0)
    const [deliveryAddrData, setDeliveryAddrData] = useState([])
    const [totalGross, setTotalGross] = useState(0)
    const [note, setNote] = useState('')
    const [totalQty, setTotalQty] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [discount1st, setDiscount1st] = useState(0)
    const [discount2nd, setDiscount2nd] = useState(0)
    const [discount3rd, setDiscount3rd] = useState(0)
    const [totalDpp, setTotalDpp] = useState(0)
    const [totalDppOth, setTotalDppOth] = useState(0)
    const [totalPpn, setTotalPpn] = useState(0)
    const [totalNetto, setTotalNetto] = useState(0)
    const [salesOrderData, setSalesOrderData] = useState([])
    const [modalSoDetail, setModalSoDetail] = useState(false)
    const [selectedSo, setSelectedSo] = useState(null)
    const [dscPercent, setDscPercent] = useState(0)
    const [dscNominal, setDscNominal] = useState(0)
    const [total, setTotal] = useState(0)
    const [totalDscItem, setTotalDscItem] = useState(0)

    const [dateRange, setDateRange] = useState([
        new Date(moment().startOf('month')),
        new Date(moment()),
    ])

    const topOpt = [
        { key: 1, text: '10 Days', value: 10 },
        { key: 2, text: '15 Days', value: 15 },
        { key: 3, text: '30 Days', value: 30 },
        { key: 4, text: '45 Days', value: 45 },
        { key: 5, text: '60 Days', value: 60 },
    ]

    const columnsProduct = useMemo(() => [
        {
            Header: "Brand",
            accessor: "brand_code"
        },
        {
            Header: "Season",
            accessor: "season"
        },
        {
            Header: "Article",
            accessor: "article"
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ cell }) => (
                <div style={{ width: '200px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    } />
                </div>
            )
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell }) => (<>{curencyNoSym.format(cell.value)}</>)
        },
        {
            Header: "Qty",
            accessor: "qty"
        },
        {
            Header: "Qty Order",
            accessor: "qty_order"
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => {
                const isInSel = isProductInSelected(cell.row.original)
                return (
                    cell.row.original.qty > 0 ?
                        <Button.Group basic size="mini">
                            <Popup size="tiny" content="Select this" trigger={
                                <Button icon={{ name: isInSel ? 'check' : 'add', color: isInSel ? 'green' : 'blue' }} onClick={() => handleAddToList(cell.row.original)} disabled={isInSel} />
                            } />
                        </Button.Group>
                        :
                        <Button.Group basic size="mini">
                            <Popup size="tiny" content="Select this" trigger={
                                <Button icon={{ name: isInSel ? 'check' : 'add', color: isInSel ? 'green' : 'blue' }} onClick={() => handleAddToList(cell.row.original)} disabled />
                            } />
                        </Button.Group>
                )
            }
        },
    ])

    const columnsSelProd = useMemo(() => [
        {
            Header: "Brand",
            accessor: "brand_code"
        },
        {
            Header: "Article",
            accessor: "article"
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ cell }) => (
                <div style={{ width: '150' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div>{cell.value}</div>
                    } />
                </div>
            )
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Dsc %",
            accessor: "dsc_percent",
            Cell: ({ cell }) => cell.value + ' %'
        },
        {
            Header: "Dsc Other",
            accessor: "dsc_nominal",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Discount",
            accessor: "total_discount",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Qty",
            accessor: "qty"
        },
        {
            Header: "Qty Order",
            accessor: "qty_order",
            Cell: ({ cell }) => (
                <div>
                    <Popup content="Edit quantity" size="tiny" trigger={
                        <Icon name="edit outline" color="blue" style={{ cursor: 'pointer' }} onClick={() => handleEditQty(cell.row.original)} />
                    } />
                    <span className="me-2 fw-bold">{cell.value}</span>
                </div>
            )
        },
        {
            Header: "Total",
            accessor: "total",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Action",
            accessor: "",
            Cell: ({ cell }) => (
                <Popup content="Remove" size="tiny" trigger={
                    <Icon name="trash alternate" color="red" onClick={() => handleRemoveItem(cell.row.original)} style={{ cursor: 'pointer' }} />
                } />
            )
        },
    ])

    const columnsSo = useMemo(() => [
        {
            Header: "Order Number",
            accessor: "header.order_number"
        },
        {
            Header: "Date",
            accessor: "header.order_date"
        },
        {
            Header: "Customer",
            accessor: "header.customer_name"
        },
        {
            Header: "Total Qty",
            accessor: "header.total_qty"
        },
        {
            Header: "Status",
            accessor: "header.status"
        },
        {
            Header: "Note",
            accessor: "header.note"
        },
        {
            Header: "Gross",
            accessor: "header.total_gross",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Discount",
            accessor: "header.total_discount",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "DPP",
            accessor: "header.total_dpp",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "PPN",
            accessor: "header.total_ppn",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Netto",
            accessor: "header.total_nett",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "User",
            accessor: "header.user_name",
            Cell: ({ cell }) => (
                <Stack direction="vertical">
                    <div>{cell.value}</div>
                    <div className="text-muted" style={{ lineHeight: '0.8em' }}>{cell.row.original.header.role_name}</div>
                </Stack>
            )
        },
        {
            Header: "Created At",
            accessor: "header.createAt"
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group basic size="mini">
                    <Popup size="tiny" content="Product Detail" trigger={
                        <Button icon={{ name: 'list layout', color: 'blue' }} onClick={() => handleViewDetail(cell.row.original.detail)} />
                    } />
                    <Popup size="tiny" content="Cancel Order" trigger={
                        <Button icon={{ name: 'ban', color: 'blue' }} onClick={() => handleCancelSo(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },
    ])

    const columnsSoDetail = useMemo(() => [
        {
            Header: "Article",
            accessor: "article"
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ cell }) => (
                <div style={{ width: '180px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    } />
                </div>
            )
        },
        {
            Header: "Barcode",
            accessor: "barcode"
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Dsc %",
            accessor: "dsc_percent",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Dsc Other",
            accessor: "dsc_nominal",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Qty",
            accessor: "qty_order"
        },
        {
            Header: "Pickup",
            accessor: "qty_pickup"
        },
        {
            Header: "Total",
            accessor: "total",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Status",
            accessor: "status"
        },
    ])

    const handleCancelSo = (row) => {
        const cfm = window.confirm("Are you sure ?")
        if (cfm) {
            axios({
                method: "POST",
                url: `${host}/directsales/index.php?action=cancelSalesOrder`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: row
            }).then(res => {
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: 'success',
                        text: res.data.msg,
                        position: 'top-right',
                    })
                    salesOrder(dateRange)
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: res.data.msg,
                        position: 'top-right',
                    })
                }
            })
        }
    }

    const handleViewDetail = (row) => {
        setSelectedSo(row)
        setModalSoDetail(true)
    }

    const handleRemoveItem = (row) => {
        const updatedData = selectedProduct.filter(item =>
            !(item.article === row.article && item.barcode === row.barcode)
        )
        setSelectedProduct(updatedData)
    }

    const handleEditQty = (row) => {
        setModalEditQty(true)
        setSelectedRow(row)
        setSelectedQty(row.qty_order)
    }

    const handleSubmitQtyOrderChange = () => {
        if (selectedRow && selectedQty > 0) {
            if (parseInt(selectedQty) <= parseInt(selectedRow.qty)) {
                const updatedData = selectedProduct.map(item => {
                    if (item.barcode === selectedRow.barcode && item.article === selectedRow.article) {
                        const updatedQtyOrder = selectedQty;
                        const newTotal = updatedQtyOrder * (item.price - (item.price * item.dsc_percent / 100) - item.dsc_nominal)
                        const discount = (item.price * item.dsc_percent / 100) + item.dsc_nominal
                        return { ...item, qty_order: updatedQtyOrder, total_discount:discount, total: newTotal };
                    }
                    return item;
                });
                
                setSelectedProduct(updatedData)
                setSelectedRow(null)
                setSelectedQty(0)
                setModalEditQty(false)
                Toaster.fire({
                    icon: 'success',
                    text: 'Success edit order qty',
                    position: 'top-right'
                })
            } else {
                Toaster.fire({
                    icon: 'warning',
                    text: 'Insufficient stock, ' + selectedRow.qty + ' left in stock',
                    position: 'top-right'
                })
            }
        } else {
            alert("No selected row...")
        }
    }

    const isProductInSelected = (product) => {
        return selectedProduct.some(selected => selected.article === product.article && selected.barcode === product.barcode)
    }

    const handleAddToList = (row) => {
        setModalParam(true)
        setSelectedRow({...row, total_discount:0})
    }

    const handleSubmitQty = () => {
        if (selectedQty > 0) {
            selectedRow.qty_order = parseInt(selectedQty)
            selectedRow.total_discount = (selectedRow.price * dscPercent / 100) + dscNominal
            setTotalDscItem((selectedRow.price * dscPercent / 100) + dscNominal)
            
            if (parseInt(selectedRow.qty) >= parseInt(selectedQty)) {
                if (!isProductInSelected(selectedRow)) {
                    setSelectedProduct(prevItems => [...prevItems, selectedRow])
                    Toaster.fire({
                        icon: 'success',
                        text: 'Product Added to the list',
                        position: 'top-right'
                    })
                    setModalParam(false)
                    setSelectedQty(0)
                    setTotal(0)
                    setDscNominal(0)
                    setDscPercent(0)
                } else {
                    Toaster.fire({
                        icon: 'warning',
                        text: 'Product has been Added to the list',
                        position: 'top-right'
                    })
                }
            } else {
                Toaster.fire({
                    icon: 'warning',
                    text: 'Insufficient stock, ' + selectedRow.qty + ' left in stock',
                    position: 'top-right'
                })
            }
        } else {
            Toaster.fire({
                icon: 'warning',
                text: 'Order quantity cannot be 0',
                position: 'top-right'
            })
        }

    }

    const handleClickAdd = () => {
        setModalCreate(true)
    }

    const customer = () => {
        axios({
            method: "GET",
            url: `${host}/directsales/index.php?action=customer`,
        }).then(res => {
            if (res.data.status === 200) {
                setCustomerOpt(
                    res.data.data.map((item, i) => (
                        {
                            key: i,
                            text: item.customer_name,
                            value: item.id_ds_customer,
                        }
                    ))
                )
                setDataCustomer(res.data.data)
            }
        })
    }

    const salesman = () => {
        axios({
            method: "GET",
            url: `${host}/directsales/index.php?action=salesman`,
        }).then(res => {
            if (res.data.status === 200) {
                setSalesOpt(
                    res.data.data.map((item, i) => (
                        {
                            key: i,
                            text: item.sales_name,
                            value: item.id_salesman,
                        }
                    ))
                )
            }
        })
    }

    const deliveryAddr = async () => {
        await axios({
            method: "GET",
            url: `${host}/directsales/index.php?action=deliveryAddr`,
        }).then(res => {
            if (res.data.status === 200) {
                setDeliveryAddrData(res.data.data)
            }
        })
    }

    const stockByWarehouse = async (idWh) => {
        setLoadProduct(true)
        await axios({
            method: "GET",
            url: `${host}/stock/index.php?action=stockListByWarehouse&id_warehouse=${idWh}`,
            headers: {
                "Content-Type": "application/json",
            }
        }).then(res => {
            if (res.data.status === 200) {
                const updatedData = res.data.data.map(item => ({ ...item, qty_order: 0, dsc_percent: 0, dsc_nominal: 0, total_discount:0, total: parseInt(item.price) }))
                setDataProduct(updatedData)
            }
        }).finally(() => setLoadProduct(false))
    }

    const handleClickBrowse = () => {
        if (warehouse) {
            stockByWarehouse(warehouse)
            setModalProduct(true)
        } else {
            Toaster.fire({
                icon: 'error',
                text: "You forget to select Warehouse",
                position: 'top-right'
            })
        }
    }

    const clearForm = () => {
        setDate(currentDate)
        setCustomerName('')
        setWarehouse('')
        setSalesName('')
        setReference('')
        setDelivery('')
        setDeliveryDetail('')
        setTop('')
        setUsePpn(true)
        setUseFaktur(false)
        setTotalPpn(0)
        setTotalDpp(0)
        setTotalGross(0)
        setTotalNetto(0)
        setTotalQty(0)
        setDiscount1st(0)
        setDiscount2nd(0)
        setDiscount3rd(0)
        setDiscount(0)
        setNote('')
        setSelectedProduct([])
    }

    const handleSubmit = () => {
        if (!customerName) {
            alert('Customer cannot be empty')
            return
        }
        if (!warehouse) {
            alert('Warehouse cannot be empty')
            return
        }
        if (!salesName) {
            alert('Salesman cannot be empty')
            return
        }
        if (!delivery || !deliveryDetail) {
            alert('Delivery address cannot be empty')
            return
        }
        if (selectedProduct == 0) {
            alert('You forget to select product...')
            return
        }

        const cfm = window.confirm("Make sure everithing has been fully checked...")

        if (cfm) {
            const totDscItem = totalDscItem 
            const data = {
                header: {
                    id_user: userProfile.profile.id_user,
                    so_date: date,
                    id_ds_customer: customerName,
                    id_warehouse: warehouse,
                    salesman: salesName,
                    reference: reference,
                    delivery: delivery,
                    delivery_addr: deliveryDetail,
                    top: top,
                    status: 'O',
                    use_ppn: usePpn ? 'Y' : 'N',
                    ppn: ppn,
                    faktur: useFaktur ? 'Y' : 'N',
                    total_ppn: totalPpn,
                    total_dpp: totalDpp,
                    total_dpp_other: totalDppOth,
                    total_gross: totalGross,
                    total_nett: totalNetto,
                    total_qty: totalQty,
                    discount1: discount1st,
                    discount2: discount2nd,
                    discount3: discount3rd,
                    total_discount: discount + totDscItem,
                    note: note,

                },
                detail: selectedProduct
            }
            
            axios({
                method: "POST",
                url: `${host}/directsales/index.php?action=createSalesOrder`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data
            }).then(res => {
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: 'success',
                        text: res.data.msg,
                        position: 'top-right',
                    })
                    alert(`Order Number : ${res.data.order_number}`)
                    clearForm()
                    setModalCreate(false)
                    salesOrder(dateRange)
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: res.data.msg,
                        position: 'top-right',
                    })
                }
            })
        }

    }
    const handleSelectAddr = (value) => {
        const selectedOpt = deliveryOpt.find(opt => opt.value === value)
        setDelivery(value)
        setDeliveryDetail(selectedOpt.address)
    }

    const salesOrder = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD');
        const [startDate, endDate] = dateRange.map(date => formatDate(date));
        const date_range = [startDate, endDate];

        await axios({
            method: "GET",
            url: `${host}/directsales/index.php?action=salesOrder`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                range: date_range
            }
        }).then(res => {
            if (res.data.status === 200) {
                setSalesOrderData(res.data.data)
            }
        })
    }

    const handleChangeDate = (e) => {
        setDateRange(e)
    }

    const handleChangeCustomer = (e, {value}) => {
        setCustomerName(value)
        const customerTop = dataCustomer.find(i => i.id_ds_customer === value).top
        const customerDelivery = deliveryAddrData.filter(i => i.id_ds_customer === value)
        setTop(customerTop)
        setDeliveryOpt(
            customerDelivery.map((item, i) => (
                {
                    key: i,
                    text: item.deliver_to,
                    value: item.id_delivery_addr,
                    address: item.address + ', ' + item.city + ', ' + item.zip_code,
                }
            ))
        )

    }

    useEffect(() => {
        if (usePpn) {
            const totQty = selectedProduct.reduce((total, item) => total + parseInt(item.qty_order), 0)
            const totGro = selectedProduct.reduce((total, item) => total + parseInt(item.qty_order) * parseFloat(item.total), 0)

            const discountStep1 = totGro * (discount1st / 100)
            const priceAfterDiscount1 = totGro - discountStep1
            const discountStep2 = priceAfterDiscount1 * (discount2nd / 100)
            const priceAfterDiscount2 = priceAfterDiscount1 - discountStep2
            const discountStep3 = priceAfterDiscount2 * (discount3rd / 100)
            const newTotalDiscount = parseFloat(discountStep1 + discountStep2 + discountStep3)
            const totAfterDsc = parseFloat(totGro - newTotalDiscount)

            const totDpp = totAfterDsc / 1.11
            const totDppOth = totDpp * (11/12)
            const totPpn = totDppOth * (12/100)
            const totNet = parseFloat(totDpp + totPpn)

            setDiscount(Math.ceil(newTotalDiscount))
            setTotalNetto(Math.ceil(totNet))
            setTotalGross(Math.ceil(totGro))
            setTotalQty(totQty)
            setTotalDpp(Math.ceil(totDpp))
            setTotalDppOth(Math.ceil(totDppOth))
            setTotalPpn(Math.ceil(totPpn))
        } else if (!usePpn) {
            const totQty = selectedProduct.reduce((total, item) => total + parseInt(item.qty_order), 0)
            const totGro = selectedProduct.reduce((total, item) => total + parseInt(item.qty_order) * parseFloat(item.total), 0)

            const discountStep1 = totGro * (discount1st / 100)
            const priceAfterDiscount1 = totGro - discountStep1
            const discountStep2 = priceAfterDiscount1 * (discount2nd / 100)
            const priceAfterDiscount2 = priceAfterDiscount1 - discountStep2
            const discountStep3 = priceAfterDiscount2 * (discount3rd / 100)
            const newTotalDiscount = parseFloat(discountStep1 + discountStep2 + discountStep3)
            const totAfterDsc = parseFloat(totGro - newTotalDiscount)

            const totDpp = totAfterDsc / 1.11
            const totDppOth = totDpp * (11/12)
            const totNet = parseFloat(totDpp)

            setDiscount(newTotalDiscount.toFixed(2))
            setTotalNetto(totNet.toFixed(2))
            setTotalGross(totGro)
            setTotalQty(totQty)
            setTotalDpp(totDpp.toFixed(2))
            setTotalDppOth(totDppOth.toFixed(2))
            setTotalPpn(0)
        }

    }, [discount1st, discount2nd, discount3rd, selectedProduct, usePpn])

    useEffect(() => {
        if (selectedRow) {
            const dscPer = selectedRow.price * dscPercent / 100
            const dscNom = dscNominal
            const newTotalItem = selectedRow.price - dscPer - dscNom
            setTotal(newTotalItem * selectedQty)

            setSelectedRow({
                ...selectedRow,
                total: newTotalItem * selectedQty,
                dsc_nominal: dscNominal,
                dsc_percent: dscPercent,
            })
        }

    }, [dscPercent, dscNominal, selectedQty])
    
    useEffect(() => {
        salesOrder(dateRange)
    }, [dateRange])

    useEffect(() => {
        customer()
        salesman()
        deliveryAddr()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Sales Order</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={6} lg={6} md={6} className="mb-3">
                        <Button content="Create Sales Order" icon="add" onClick={handleClickAdd} color="blue" />
                    </Col>
                    <Col xl={6} lg={6} md={6} className="text-end">
                        <DateRangePicker
                            className="mb-2"
                            label="Date Range : "
                            format="yyyy-MM-dd"
                            placement="auto"
                            size="md"
                            cleanable={true}
                            defaultValue={dateRange}
                            value={dateRange}
                            onChange={handleChangeDate}
                            onClean={() => setDateRange([
                                new Date(moment().startOf('month')),
                                new Date(moment()),
                            ])}
                        />
                    </Col>
                    <Col xl={12} lg={12} md={12}>
                        <Reacttable data={salesOrderData} columns={columnsSo} pageRow={10} noData={salesOrderData.length == 0} />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalCreate} onClose={() => setModalCreate(false)} overflow={false} backdrop="static" size="lg">
                <Modal.Header>
                    <Modal.Title>Create Sales Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12}>
                            <Form id="frmCreate" onSubmit={handleSubmit}>
                                <FormGroup>
                                    <FormInput className="small" type="date" label="Date" value={date} onChange={(e, { value }) => setDate(value)} />
                                    <FormSelect className="small" options={customerOpt} label="Customer" width={8} value={customerName} onChange={handleChangeCustomer} required />
                                    <FormSelect className="small" options={warehouseOpt} label="Warehouse" width={8} value={warehouse} onChange={(e, { value }) => setWarehouse(value)} required />
                                    <FormSelect className="small" options={salesOpt} label="Salesman" value={salesName} onChange={(e, { value }) => setSalesName(value)} required />
                                </FormGroup>
                                <FormGroup>
                                    <FormInput className="small" label="Reference" maxLength="30" value={reference} onChange={(e, { value }) => setReference(value)} />
                                    <FormSelect className="small" label="Delivery Address" options={deliveryOpt} value={delivery} onChange={(e, { value }) => handleSelectAddr(value)} required />
                                    <FormInput className="small" label="TOP" value={top} width={2} readOnly />
                                    <FormField style={{marginTop:'30px'}} className="ms-5">
                                            <FormCheckbox label="PPN" checked={usePpn} onChange={(e, data) => setUsePpn(data.checked)} readOnly/>
                                    </FormField>
                                    <FormField style={{marginTop:'30px'}}>
                                        <FormCheckbox label="Faktur Pajak" checked={useFaktur} onChange={(e, data) => setUseFaktur(data.checked)} />
                                    </FormField>
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col lg={12} md={12} className="mb-3">
                            <Button content="Browse Product" color="orange" size="tiny" onClick={handleClickBrowse} circular icon={{ name: 'search' }} />
                        </Col>
                        <Col lg={12} md={12}>
                            <Reacttable data={selectedProduct} columns={columnsSelProd} pageRow={5} noData={selectedProduct} />
                        </Col>
                        <Col lg={4} md={4}>
                            <Form>
                                <FormField>
                                    <label>Note</label>
                                    <TextArea maxLength="100" value={note} onChange={(e, { value }) => setNote(value)} />
                                </FormField>
                            </Form>
                        </Col>
                        <Col lg={4} md={4} className="text-end">
                            <div className="mb-2">
                                <label style={{ fontSize: '0.9em', marginRight: '10px' }}>Total Gross</label>
                                <Input size="small" style={{ width: '16em' }} value={curencyNoSym.format(totalGross)} readOnly />
                            </div>
                            <div className="mb-2">
                                <label style={{ fontSize: '0.9em', marginRight: '10px' }}>Discount</label>
                                <Input className="me-2" size="small" style={{ width: '5em' }} value={discount1st} onChange={(e, { value }) => setDiscount1st(value)} label={{ icon: 'percent' }} labelPosition='right corner' />
                                <Input className="me-2" size="small" style={{ width: '5em' }} value={discount2nd} onChange={(e, { value }) => setDiscount2nd(value)} label={{ icon: 'percent' }} labelPosition='right corner' />
                                <Input size="small" style={{ width: '5em' }} value={discount3rd} onChange={(e, { value }) => setDiscount3rd(value)} label={{ icon: 'percent' }} labelPosition='right corner' />
                            </div>
                            <div className="mb-2">
                                <Input size="small" style={{ width: '16em' }} value={curencyNoSym.format(discount)} readOnly />
                            </div>
                        </Col>
                        <Col lg={4} md={4} className="text-end">
                            <div className="mb-2">
                                <label style={{ fontSize: '0.9em', marginRight: '10px' }} >DPP</label>
                                <Input size="small" value={curencyNoSym.format(totalDpp)} readOnly />
                            </div>
                            <div className="mb-2">
                                <label style={{ fontSize: '0.9em', marginRight: '10px' }} >DPP Other</label>
                                <Input size="small" value={curencyNoSym.format(totalDppOth)} readOnly />
                            </div>
                            <div className="mb-2">
                                <label style={{ fontSize: '0.9em', marginRight: '10px' }} >PPN</label>
                                <Input size="small" label="12%" value={curencyNoSym.format(totalPpn)} readOnly />
                            </div>
                            <div className="mb-2">
                                <label style={{ fontSize: '0.9em', marginRight: '10px' }} >Total Netto</label>
                                <Input size="small" value={curencyNoSym.format(totalNetto)} readOnly />
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit Sales Order" type="submit" form="frmCreate" color="blue" icon="save" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalProduct} onClose={() => setModalProduct(false)} overflow={false} size="md">
                <Modal.Header>
                    <Modal.Title>Product List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Dimmer active={loadProduct} inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                    <Reacttable data={dataProduct} columns={columnsProduct} pageRow={10} />
                </Modal.Body>
            </Modal>

            <Modal open={modalParam} onClose={() => setModalParam(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Order Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-2 fw-bold">Normal Price: {curencyNoSym.format(selectedRow?.price)}</div>
                    <Form id="frmQty" onSubmit={handleSubmitQty}>
                        <FormInput type="number" maxLength="5" min="0" label="How much ?" value={selectedQty} onChange={(e, { value }) => setSelectedQty(value)} />
                        <FormInput type="number" min="0" label="Discount in %" max="99" value={dscPercent} onChange={(e, { value }) => setDscPercent(value)} />
                        <FormInput type="number" label="Discount in Nominal" value={dscNominal} onChange={(e, { value }) => setDscNominal(value)} />
                        <FormInput type="text" label="Total" value={total} readOnly />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" type="submit" form="frmQty" color="blue" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalEditQty} onClose={() => setModalEditQty(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Edit Order Quantity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmEditQty" onSubmit={handleSubmitQtyOrderChange}>
                        <FormInput type="number" maxLength="5" min="0" label="How much ?" value={selectedQty} onChange={(e, { value }) => setSelectedQty(value)} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" type="submit" form="frmEditQty" color="blue" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalSoDetail} onClose={() => setModalSoDetail(false)} backdrop="static" overflow={false} size="lg">
                <Modal.Header>
                    <Modal.Title>Sales Order Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable data={selectedSo} columns={columnsSoDetail} pageRow={10} totals={['qty_order', 'total', 'qty_pickup']} />
                </Modal.Body>
            </Modal>
        </>
    )
}