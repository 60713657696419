import React, { useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import { useMyGlobal } from "../../component/Global";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Stack from "react-bootstrap/Stack"
import { Button, Dimmer, Form, FormCheckbox, FormField, FormGroup, FormInput, FormSelect, FormTextArea, Icon, Input, Loader, Popup, TextArea } from "semantic-ui-react";
import Modal from "rsuite/Modal";
import { capitalize, curency, curencyNoSym, currentDate } from "../../component/utils";
import { Toaster } from "../../component/Toaster";
import { Reacttable } from "../../component/Reacttable";
import moment from "moment";
import { DateRangePicker } from "rsuite";


export const DeliveryOrder = () => {
    const [modalCreate, setModalCreate] = useState(false)
    const [modalSoProduct, setModalSoProduct] = useState(false)
    const [modalAddQty, setModalAddQty] = useState(false)
    const [modalDoDetail, setModalDoDetail] = useState(false)
    const { host, userProfile, cityOpt, provOpt, warehouseOpt } = useMyGlobal()
    const [deliveryOrderData, setDeliveryOrderData] = useState([])
    const [customerOpt, setCustomerOpt] = useState([])
    const [salesOrderOpt, setSalesOrderOpt] = useState([])
    const [orderData, setOrderData] = useState(null)
    const [selectedOrder, setSelectedOrder] = useState(null)
    const [orderNumber, setOrderNumber] = useState('')
    const [idWarehouse, setIdWarehouse] = useState('')
    const [warehouseDesc, setWarehouseDesc] = useState('')
    const [reference, setReference] = useState('')
    const [doDate, setDoDate] = useState(currentDate)
    const [doType, setDoType] = useState('')
    const [customer, setCustomer] = useState('')
    const [usePkp, setUsePkp] = useState(false)
    const [soProduct, setSoProduct] = useState([])
    const tableRef = useRef()
    const [selectedProduct, setSelectedProduct] = useState([])
    const [qtyDeliver, setQtyDeliver] = useState(1)
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedRowDo, setSelectedRowDo] = useState(null)
    const [note, setNote] = useState('')
    const [totalQty, setTotalQty] = useState(0)
    const [total, setTotal] = useState(0)

    const typeOpt = [
        { key: 0, text: 'SJG - Surat Jalan Putus', value: 'SJG' },
        { key: 1, text: 'RSJ - Retur Surat Jalan', value: 'RSJ' },
    ]

    const [dateRange, setDateRange] = useState([
        new Date(moment().startOf('month')),
        new Date(moment()),
    ])

    const columnSoProduct = useMemo(() => [
        {
            Header: "Article",
            accessor: "article"
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ cell }) => (
                <div style={{ width: '150px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>}
                    />
                </div>
            )
        },
        {
            Header: "Barcode",
            accessor: "barcode"
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Dsc %",
            accessor: "dsc_percent"
        },
        {
            Header: "Dsc Other",
            accessor: "dsc_nominal"
        },
        {
            Header: "Ord Qty",
            accessor: "qty_order"
        },
        {
            Header: "Total",
            accessor: "total",
            Cell:({cell}) => curencyNoSym.format(cell.value)
        },
        {
            Header: "Pickup Qty",
            accessor: "qty_pickup",
        },
        {
            Header: "",
            accessor: "added",
            Cell: ({ cell }) => (
                cell.value === 'Y' ? <Icon name="check" /> : <></>
            )
        }
    ])

    const columnsSelectProduct = useMemo(() => [
        {
            Header: "Article",
            accessor: "article"
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ cell }) => (
                <div style={{ width: '150px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>}
                    />
                </div>
            )
        },
        {
            Header: "Barcode",
            accessor: "barcode"
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Dsc %",
            accessor: "dsc_percent",
            Cell:({cell}) => cell.value + ' %'
        },
        {
            Header: "Dsc Oth",
            accessor: "dsc_nominal"
        },
        {
            Header: "Ord Qty",
            accessor: "qty_order"
        },
        {
            Header: "Qty",
            accessor: "qty_pickup",
            Cell: ({ cell }) => (
                <div>
                    <span>
                        <Popup content="Edit delivery quantity" size="tiny" trigger={
                            <Icon name="edit outline" color="blue" style={{ cursor: 'pointer' }} onClick={() => handleClickAdjustQty(cell.row.original)} />
                        } />
                    </span>
                    <span className="fw-bold">{cell.value}</span>
                </div>
            )
        },
        {
            Header: "Total",
            accessor: "total",
            Cell: ({ cell }) => (<div className="fw-bold">{curencyNoSym.format(cell.value)}</div>)
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Popup size="tiny" content="Remove Product" trigger={
                    <Icon name="trash alternate" color="red" style={{ cursor: 'pointer' }} onClick={() => handleRemoveItem(cell.row.original)} />
                } />
            )
        },
    ])

    const columnsDo = useMemo(() => [
        {
            Header: "DO Number",
            accessor: "header.do_number"
        },
        {
            Header: "Date",
            accessor: "header.do_date"
        },
        {
            Header: "DO Type",
            accessor: "header.do_type"
        },
        {
            Header: "Order Number",
            accessor: "header.order_number"
        },
        {
            Header: "Customer",
            accessor: "header.customer_name"
        },
        {
            Header: "Warehouse",
            accessor: "header.warehouse_name"
        },
        {
            Header: "Status",
            accessor: "header.do_status"
        },
        {
            Header: "Note",
            accessor: "header.note"
        },
        {
            Header: "User",
            accessor: "header.user_name",
            Cell: ({ cell }) => (
                <Stack direction="vertical">
                    <div>{cell.value}</div>
                    <div className="text-muted" style={{ lineHeight: '0.8em' }}>{cell.row.original.header.role_name}</div>
                </Stack>
            )
        },
        {
            Header: "Created At",
            accessor: "header.createAt"
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group basic size="mini">
                    <Popup size="tiny" content="Product Detail" trigger={
                        <Button icon={{ name: 'list layout', color: 'blue' }} onClick={() => handleViewDetail(cell.row.original)} />
                    } />
                    <Popup size="tiny" content="Cancel Delivery Order" trigger={
                        <Button icon={{ name: 'ban', color: 'blue' }} onClick={() => handleCancelDo(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },
    ])

    const columnDoDetail = useMemo(() => [
        {
            Header: "Article",
            accessor: "article"
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ cell }) => (
                <div style={{ width: '150px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    } />
                </div>
            )
        },
        {
            Header: "Barcode",
            accessor: "barcode"
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "Dsc %",
            accessor: "dsc_percent",
            Cell:({cell}) => cell.value + ' %'
        },
        {
            Header: "Dsc Oth",
            accessor: "dsc_nominal",
            Cell:({cell}) => curencyNoSym.format(cell.value)
        },
        {
            Header: "Qty Order",
            accessor: "qty_order"
        },
        {
            Header: "Qty Pickup",
            accessor: "qty_pickup"
        },
        {
            Header: "Status",
            accessor: "status",
            Cell: ({ cell }) => cell.value === 'A' ? 'Acomplished' : 'Partial'
        },
    ])

    const handleViewDetail = (row) => {
        setSelectedRowDo(row)
        setModalDoDetail(true)
    }

    const handleCancelDo = (row) => {
        const cfm = window.confirm("Are you sure ?")
        if (cfm) {
            axios({
                method: "DELETE",
                url: `${host}/directsales/index.php?action=cancelDeliveryOrder`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: row
            }).then(res => {
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: 'success',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                    deliveryOrder(dateRange)
                    salesOrder()
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                }
            })
        }
    }

    const handleClickAdjustQty = (row) => {
        setSelectedRow(row)
        setQtyDeliver(row.qty_pickup)
        setModalAddQty(true)
    }

    const handleRemoveItem = (row) => {
        const updatedData = selectedProduct.filter(item =>
            !(item.article === row.article && item.barcode === row.barcode)
        )
        setSelectedProduct(updatedData)
        setSoProduct(prevSoProduct => prevSoProduct.map(item => item.article === row.article && item.barcode === row.barcode ? { ...item, added: 'N' } : item));
    }

    const getCustomer = () => {
        axios({
            method: "GET",
            url: `${host}/directsales/index.php?action=customer`,
        }).then(res => {
            if (res.data.status === 200) {
                setCustomerOpt(
                    res.data.data.map((item, i) => (
                        {
                            key: i,
                            text: item.customer_name,
                            value: item.id_ds_customer,
                        }
                    ))
                )
            }
        })
    }

    const handleClickAdd = () => {
        setModalCreate(true)
    }

    const salesOrder = async () => {
        await axios({
            method: "GET",
            url: `${host}/directsales/index.php?action=salesOrder`,
            headers: {
                "Content-Type": "application/json",
            }
        }).then(res => {
            if (res.data.status === 200) {
                setOrderData(res.data.data)
            }
        })
    }

    const handleChangeCustomer = (value) => {
        setCustomer(value)
        const orderOption = orderData.filter(item => item.header.id_ds_customer === value).map((item, i) => ({
            key: i,
            text: `${item.header.order_number} - ${item.header.customer_name}`,
            value: item.header.order_number,
        }))
        setSalesOrderOpt(orderOption)
    }

    const handleChangeOrderNumber = (value) => {
        setOrderNumber(value)
        setWarehouseDesc(orderData.find(i => i.header.order_number === value).header.warehouse_name)
        setIdWarehouse(orderData.find(i => i.header.order_number === value).header.id_warehouse)
    }

    const handleClickBrowse = () => {
        setModalSoProduct(true)
    }

    const handleGetSelected = () => {
        if (tableRef.current) {
            const selected = tableRef.current.getSelectedRows();

            setSelectedProduct(prev => {
                const updatedList = [...prev];

                selected.map(originalItem => {
                    if (originalItem.article && originalItem.barcode) {
                        const isDuplicate = updatedList.some(existingItem =>
                            existingItem.article === originalItem.article &&
                            existingItem.barcode === originalItem.barcode
                        );

                        if (!isDuplicate) {
                            // Buat salinan dari originalItem sebelum memodifikasi jangan langsung di ubah yang original nya nanti ikut ke update di soProduct ya
                            const newItem = { ...originalItem };
                            newItem.qty_pickup = newItem.qty_order - newItem.qty_pickup;
                            // newItem.total = newItem.qty_pickup * newItem.price;
                            
                            updatedList.push(newItem);

                            setSoProduct(prevSoProduct =>
                                prevSoProduct.map(item =>
                                    item.article === newItem.article && item.barcode === newItem.barcode
                                        ? { ...item, added: 'Y' }
                                        : item
                                )
                            )
                            setModalSoProduct(false);
                        } else {
                            alert(`Item with article ${originalItem.article} and barcode ${originalItem.barcode} already selected.`);
                        }
                    }
                });

                console.log('selected', selected);
                return updatedList;
            });
        }
    }

    const handleSubmitNewQty = () => {
        const soProductPickup = soProduct.find(i => i.article === selectedRow.article && i.barcode === selectedRow.barcode).qty_pickup
        if (selectedRow) {
            const article = selectedRow.article
            const barcode = selectedRow.barcode
            const currentQty = selectedRow.qty_order - soProductPickup

            if (currentQty >= qtyDeliver) {
                setSelectedProduct(prevProducts =>
                    prevProducts.map(product =>
                        product.article === article && product.barcode === barcode
                            ? { ...product, 
                                qty_pickup: qtyDeliver, 
                                total: qtyDeliver * (product.price - (product.price * (product.dsc_percent / 100)) - product.dsc_nominal)
                            }                            
                            : product
                    )
                )
                setModalAddQty(false)
            } else {
                alert(`Invalid Quantity\ncurrent qty order is : ${currentQty}`)
            }
        }
    }

    const clearForm = () => {
        setCustomer('')
        setDoDate(currentDate)
        setOrderNumber('')
        setDoType('')
        setWarehouseDesc('')
        setReference('')
        setUsePkp(false)
        setNote('')
        setSelectedProduct([])
    }

    const deliveryOrder = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD');
        const [startDate, endDate] = dateRange.map(date => formatDate(date));
        const date_range = [startDate, endDate];

        await axios({
            method: "GET",
            url: `${host}/directsales/index.php?action=deliveryOrder`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                range: date_range
            }
        }).then(res => {
            if (res.data.status === 200) {
                setDeliveryOrderData(res.data.data)
            }
        })
    }

    const handleSubmit = () => {
        const data = {
            header: {
                id_user: userProfile.profile.id_user,
                do_date: doDate,
                do_type: doType,
                id_ds_customer: customer,
                order_number: orderNumber,
                id_warehouse: idWarehouse,
                reference: reference,
                note: note,
                pkp: usePkp ? 'Y' : 'N',
                total_qty:totalQty,
                total:total,
            },
            detail: selectedProduct
        }
        
        axios({
            method: "POST",
            url: `${host}/directsales/index.php?action=createDeliveryOrder`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: res.data.msg,
                    position: 'top-right'
                })
                clearForm()
                setModalCreate(false)
                deliveryOrder(dateRange)
                salesOrder()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const handleChangeDate = (e) => {
        setDateRange(e)
    }

    useEffect(() => {
        const selectedOrder = orderData?.find(i => i.header.order_number === orderNumber && (i.header.status === 'O' || i.header.status === 'P'));
        if (selectedOrder) {
            const updatedOrder = {
                ...selectedOrder, detail: selectedOrder.detail.map(detailItem => (
                    { ...detailItem }
                ))
            }
            setSelectedOrder(updatedOrder)
        }
    }, [orderNumber, orderData])

    useEffect(() => {
        if (selectedOrder) {
            const orderProduct = selectedOrder.detail.map(item => ({ ...item, added: 'N' }));
            setSoProduct(orderProduct)
        }
    }, [selectedOrder])

    useEffect(() => {
        const total = selectedProduct.reduce((total, i) => total + parseInt(i.total),0)
        const totalQty = selectedProduct.reduce((total, i) => total + parseInt(i.qty_pickup),0)
        setTotal(total)
        setTotalQty(totalQty)
    }, [selectedProduct])

    useEffect(() => {
        deliveryOrder(dateRange)
    }, dateRange)

    useEffect(() => {
        getCustomer()
        salesOrder()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Delivery Order</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={6} lg={6} md={6} className="mb-3">
                        <Button content="Create Delivery Order" icon="add" onClick={handleClickAdd} color="blue" />
                    </Col>
                    <Col xl={6} lg={6} md={6} className="text-end">
                        <DateRangePicker
                            className="mb-2"
                            label="Date Range : "
                            format="yyyy-MM-dd"
                            placement="auto"
                            size="md"
                            cleanable={true}
                            defaultValue={dateRange}
                            value={dateRange}
                            onChange={handleChangeDate}
                            onClean={() => setDateRange([
                                new Date(moment().startOf('month')),
                                new Date(moment()),
                            ])}
                        />
                    </Col>
                    <Col xl={12} lg={12} md={12}>
                        <Reacttable data={deliveryOrderData} columns={columnsDo} pageRow={10} noData={deliveryOrderData.length === 0} />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalCreate} onClose={() => setModalCreate(false)} overflow={false} backdrop="static" size="lg">
                <Modal.Header>
                    <Modal.Title>Create Delivery Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            <Form id="frmCreate" onSubmit={handleSubmit}>
                                <Form.Group>
                                    <FormInput type="date" label="Date" className="small" value={doDate} onChange={(e, { value }) => setDoDate(value)} />
                                    <FormSelect options={typeOpt} className="small" label="Type" width={4} value={doType} onChange={(e, { value }) => setDoType(value)} />
                                    <FormSelect options={customerOpt} className="small" label="Customer" search width={4} value={customer} onChange={(e, { value }) => handleChangeCustomer(value)} />
                                    <FormSelect options={salesOrderOpt} className="small" label="Order Number" search width={6} value={orderNumber} onChange={(e, { value }) => handleChangeOrderNumber(value)} />
                                </Form.Group>
                                <Form.Group>
                                    <FormInput type="text" className="small" label="Warehouse" readOnly value={warehouseDesc} width={5} />
                                    <FormInput type="text" className="small" label="Reference" value={reference} onChange={(e, { value }) => setReference(value)} maxLength={25} width={4} />
                                    <FormCheckbox label="PKP" style={{ marginTop: '30px' }} checked={usePkp} onChange={(e, data) => setUsePkp(data.checked)} className="ms-4 small" />
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col xl={12} lg={12} md={12} className="mb-3">
                            <Button content="Browse Product" icon="search" size="tiny" color="orange" circular onClick={() => handleClickBrowse()} />
                        </Col>
                        <Col xl={12} lg={12} md={12}>
                            <Reacttable data={selectedProduct} columns={columnsSelectProduct} pageRow={10} noData={selectedProduct.length === 0} totals={['qty_order', 'qty_pickup', 'total']} />
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                            <Form>
                                <FormTextArea label="Note" maxLength="100" value={note} onChange={(e, { value }) => setNote(value)} />
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Create Delivery Order" form="frmCreate" type="submit" color="blue" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalSoProduct} onClose={() => setModalSoProduct(false)} overflow={false} size="lg">
                <Modal.Header>
                    <Modal.Title>Product From Sales Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 fw-bold">Order Number : {selectedOrder?.header.order_number}</div>
                    <Reacttable
                        data={soProduct}
                        columns={columnSoProduct}
                        pageRow={10}
                        noData={soProduct.length === 0}
                        checkbox
                        ref={tableRef}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Confirm Selected Product" color="blue" onClick={handleGetSelected} />
                </Modal.Footer>
            </Modal>

            <Modal open={modalAddQty} onClose={() => setModalAddQty(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Adjust Quantity for Delivery</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmAdjustQty" onSubmit={handleSubmitNewQty}>
                        <Form.Group grouped>
                            <FormInput type="number" value={qtyDeliver} onChange={(e, { value }) => setQtyDeliver(value)} maxLength={8} min={1} label="How Much ?" />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" type="submit" form="frmAdjustQty" color="blue" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalDoDetail} onClose={() => setModalDoDetail(false)} overflow={false} backdrop="static" size="lg">
                <Modal.Header>
                    <Modal.Title>Delivery Order Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 fw-bold">DO Number : {selectedRowDo?.header.do_number}</div>
                    <Reacttable data={selectedRowDo?.detail} columns={columnDoDetail} pageRow={10} noData={selectedRowDo} totals={['qty_order', 'qty_pickup']} />
                </Modal.Body>
            </Modal>
        </>
    )
}