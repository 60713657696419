import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useMyGlobal } from "../../component/Global";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Stack from "react-bootstrap/Stack"
import { Button, Checkbox, Form, FormGroup, FormInput, FormRadio, FormSelect, Icon, Label, Popup } from "semantic-ui-react";
import Modal from "rsuite/Modal";
import { capitalize } from "../../component/utils";
import { Toaster } from "../../component/Toaster";
import { Reacttable } from "../../component/Reacttable";


export const CustomerType = () => {
    const [modalCreate, setModalCreate] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const { host, userProfile, cityOpt, provOpt } = useMyGlobal()
    const [customerTypeData, setCustomerTypeData] = useState([])
    const [codeType, setCodeType] = useState('')
    const [desc, setDesc] = useState('')
    const [shortDesc, setShortDesc] = useState('')
    const [idCustomerType, setIdCustomerType] = useState('')

    const columnsType = useMemo(() => [
        {
            Header: "ID",
            accessor: "id_customer_type"
        },
        {
            Header: "Customer Type Code",
            accessor: "code_customer_type"
        },
        {
            Header: "Short Description",
            accessor: "short_description"
        },
        {
            Header: "Description",
            accessor: "description"
        },
        {
            Header: "Create At",
            accessor: "createAt"
        },
        {
            Header: "User",
            accessor: "user_name",
            Cell: ({ cell }) => (
                <Stack direction="vertical">
                    <div>{cell.value}</div>
                    <div className="text-muted" style={{ lineHeight: '0.8em' }}>{cell.row.original.role_name}</div>
                </Stack>
            )
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group basic size="mini">
                    <Popup size="tiny" content="Edit" trigger={
                        <Button icon={{ name: 'edit', color: 'blue' }} onClick={() => handleClickEdit(cell.row.original)} />
                    } />
                    <Popup size="tiny" content="Delete" trigger={
                        <Button icon={{ name: 'trash alternate', color: 'blue' }} onClick={() => handleClickDelete(cell.row.original.id_customer_type)} />
                    } />
                </Button.Group>
            )
        },
    ])

    const handleClickEdit = (row) => {
        setIdCustomerType(row.id_customer_type)
        setCodeType(row.code_customer_type)
        setDesc(row.description)
        setShortDesc(row.short_description)
        setModalEdit(true)
    }

    const handleClickDelete = (id) => {
        const cfm = window.confirm("Are you sure ?")

        if (cfm) {
            axios({
                method: "DELETE",
                url: `${host}/directsales/index.php?action=deleteCustomerType`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    id_customer_type: id
                }
            }).then(res => {
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: 'success',
                        text: res.data.msg,
                        position: 'top-right',
                    })
                    customerType()
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: res.data.msg,
                        position: 'top-right',
                    })
                }
            })
        }
    }

    const handleClickAdd = () => {
        setModalCreate(true)
        clearForm()
    }

    const clearForm = () => {
        setCodeType('')
        setDesc('')
        setShortDesc('')
    }

    const handleSubmitEdit = () => {
        const data = {
            id_user: userProfile.profile.id_user,
            id_customer_type: idCustomerType,
            code_customer_type: codeType,
            short_description: shortDesc,
            description: desc,
        }

        axios({
            method: "PUT",
            url: `${host}/directsales/index.php?action=editCustomerType`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: res.data.msg,
                    position: 'top-right'
                })
                clearForm()
                setModalEdit(false)
                customerType()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const handleSubmit = () => {
        const param = new FormData()

        param.append('id_user', userProfile.profile.id_user)
        param.append('code_customer_type', codeType)
        param.append('short_description', shortDesc)
        param.append('description', desc)

        axios({
            method: "POST",
            url: `${host}/directsales/index.php?action=createCustomerType`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data: param
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: res.data.msg,
                    position: 'top-right'
                })
                clearForm()
                setModalCreate(false)
                customerType()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const customerType = async () => {
        await axios({
            method: "GET",
            url: `${host}/directsales/index.php?action=customerType`,
        }).then(res => {
            if (res.data.status === 200) {
                setCustomerTypeData(res.data.data)
            }
        })
    }

    useEffect(() => {
        customerType()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Customer Type</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={10} lg={10} md={12} className="mb-3">
                        <Button content="Add New Customer Type" icon="add" onClick={handleClickAdd} color="blue" />
                    </Col>
                    <Col xl={10} lg={10} md={12}>
                        <Reacttable data={customerTypeData} columns={columnsType} pageRow={10} />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalCreate} onClose={() => setModalCreate(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Create New Customer Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmCreate" onSubmit={handleSubmit}>
                        <Form.Group grouped>
                            <FormInput type="text" label="Customer Type Code" maxLength="6" value={codeType} onChange={(e, { value }) => setCodeType(value)} required />
                            <FormInput type="text" label="Short Description" maxLength="35" value={shortDesc} onChange={(e, { value }) => setShortDesc(value)} required />
                            <FormInput type="text" label="Description" maxLength="50" value={desc} onChange={(e, { value }) => setDesc(value)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit New Type" type="submit" color="blue" form="frmCreate" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalEdit} onClose={() => setModalEdit(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Edit Customer Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmEdit" onSubmit={handleSubmitEdit}>
                        <Form.Group grouped>
                            <FormInput type="text" label="Customer Type Code" maxLength="6" value={codeType} onChange={(e, { value }) => setCodeType(value)} required />
                            <FormInput type="text" label="Short Description" maxLength="35" value={shortDesc} onChange={(e, { value }) => setShortDesc(value)} required />
                            <FormInput type="text" label="Description" maxLength="50" value={desc} onChange={(e, { value }) => setDesc(value)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit Edit Type" type="submit" color="blue" form="frmEdit" />
                </Modal.Footer>
            </Modal>
        </>
    )
}