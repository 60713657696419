import React, { useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Stack from "react-bootstrap/Stack"
import axios from "axios";
import { Button, Form, FormRadio, Input, Popup } from "semantic-ui-react";
import { useMyGlobal } from "../component/Global";
import Modal from "rsuite/Modal"
import { currentDate } from "../component/utils";
import moment from "moment";
import { Toaster } from "../component/Toaster";
import { Reacttable } from "../component/Reacttable";
import Badge from "rsuite/Badge";

export const SalesAgent = () => {
    const {host, userProfile, showroomOpt} = useMyGlobal()
    const [modalAddNew, setModalAddNew] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const [dataAgent, setDataAgent] = useState([])
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [marit, setMarit] = useState('')
    const [dob, setDob] = useState(currentDate)
    const [age, setAge] = useState(0)
    const [edu, setEdu] = useState('')
    const [shoeSize, setShoeSize] = useState('')
    const [shirtSize, setShirtSize] = useState('')
    const [showroom, setShowroom] = useState('')
    const [salesType, setSalesType] = useState('offline')
    const [selectDataEdit, setSelectDataEdit] = useState(null)
    const [showroomFilter, setShowroomFilter] = useState([])
    const maritalOpt = [
        {key:0, text:'Kawin', value:'kawin'},
        {key:1, text:'Belum Kawin', value:'belum kawin'},
        {key:3, text:'Lajang', value:'lajang'},
        {key:4, text:'Bercerai', value:'bercerai'}
    ]
    const lastEdu = [
        {key:0, text:'Sekolah Menengah Pertama', value:'SMP'},
        {key:1, text:'Sekolah Menengah Atas', value:'SMA'},
        {key:2, text:'Diploma 1', value:'D1'},
        {key:3, text:'Diploma 3', value:'D3'},
        {key:4, text:'Sarjana', value:'S1'}
    ]
    const shirtSizeOpt = [
        {key:0, text:'Extra Small', value:'xs'},
        {key:1, text:'Small', value:'s'},
        {key:2, text:'Medium', value:'m'},
        {key:3, text:'Large', value:'l'},
        {key:4, text:'Extra Large', value:'xl'}
    ]
    const shoeSizeOpt = [
        {key:0, text:'37', value:'37'},
        {key:1, text:'38', value:'38'},
        {key:2, text:'39', value:'39'},
        {key:3, text:'40', value:'40'},
        {key:4, text:'41', value:'41'},
        {key:5, text:'42', value:'42'},
        {key:6, text:'44', value:'43'},
        {key:7, text:'44', value:'44'},
        {key:8, text:'45', value:'45'},
        {key:9, text:'46', value:'46'},
        {key:10, text:'47', value:'47'},
        {key:11, text:'48', value:'48'},
    ]

    const columnsAgent = useMemo (() => [
        {
            Header: "ID",
            accessor: "id"
        },
        {
            Header: "Showroom",
            accessor: "showroom_name"
        },
        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Age",
            accessor: "age",
            Cell:({cell}) => (
                <div>{cell.value} Years</div>
            )
        },
        {
            Header: "Education",
            accessor: "last_edu"
        },
        {
            Header: "Shoe Size",
            accessor: "shoe_size",
            Cell : ({cell}) => (
                <div>{cell.value} EU</div>
            )
        },
        {
            Header: "Shirt Size",
            accessor: "shirt_size",
            Cell:({cell}) => (
                <div className="text-uppercase">{cell.value}</div>
            )
        },
        {
            Header: "Sales Type",
            accessor: "sales_type",
            Cell:({cell}) => (
                <div>
                    <span className="me-2">{cell.value === 'offstore' ? <Badge color="blue"/> : cell.value === 'marketplace' ? <Badge color="orange"/> :<></>}</span>
                    <span className="text-capitalize">{cell.value}</span>
                </div>
            )
        },
        {
            Header: "Entry User",
            accessor: "user_name",
            Cell:({cell}) => (
                <Stack direction="vertical">
                    <div>{cell.value}</div>
                    <div className="text-muted" style={{lineHeight:'0.7em'}}>{cell.row.original.role_name}</div>
                </Stack>
            )
        },
        {
            Header: "Action",
            accessor: null,
            Cell:({cell}) => (
                <Button.Group size="mini" basic>
                    <Popup content="Edit" size="tiny" trigger={
                        <Button icon={{name:'edit', color:'blue'}} onClick={() => handleSelectEdit(cell.row.original)}/>
                    }/>
                    <Popup content="Delete" size="tiny" trigger={
                        <Button icon={{name:'trash alternate', color:'blue'}} onClick={()=>deleteAgent(cell.row.original.id)}/>
                    }/>
                </Button.Group>
            )
        },
    ])

    const handleClickAddNew = () => {
        clearForm()
        setModalAddNew(true)
    }

    const handleSelectEdit = (data) => {
        setSelectDataEdit(data)
        setShowroom(data.id_showroom)
        setName(data.name)
        setAddress(data.address)
        setMarit(data.marital_status)
        setDob(data.dob)
        setAge(data.age)
        setEdu(data.last_edu)
        setShoeSize(data.shoe_size)
        setShirtSize(data.shirt_size)
        setSalesType(data.sales_type)
        setModalEdit(true)
    }

    const clearForm = () => {
        setShowroom('')
        setSelectDataEdit(null)
        setName('')
        setAddress('')
        setMarit('')
        setDob('')
        setAge(0)
        setEdu('')
        setShoeSize('')
        setSalesType('')
        setShirtSize('')
    }

    const handleSubmitEdit = () => { 
        const param = {
            id:selectDataEdit.id,
            id_showroom:showroom,
            name:name,
            address:address,
            marital_status:marit,
            dob:dob,
            age:age,
            last_edu:edu,
            shoe_size:shoeSize,
            shirt_size:shirtSize,
            sales_type:salesType,
            id_user:userProfile.profile.id_user,
        }
        
        if (parseInt(selectDataEdit.age) >= 17){
            axios({
                method: "PUT",
                url: `${host}/sales/index.php?action=EditAgent`,
                headers: {
                    "Content-Type": "application/json",
                },
                data:param
            }).then(res => {
                if (res.data.status === 200){
                    Toaster.fire({
                        icon:"success",
                        text:'Success update Agent data',
                        position:'top-right'
                    })
                    getSalesAgentData()
                    setModalEdit(false)
                    clearForm()
                } else {
                    Toaster.fire({
                        icon:"error",
                        text:res.data.msg,
                        position:'top-right'
                    })
                }
            })
        }
    }

    const handleSubmitAdd = () => {
        if (parseInt(age) >= 17){
            const param = new FormData()

            param.append('showroom', showroom)
            param.append('name', name)
            param.append('address', address)
            param.append('marit', marit)
            param.append('dob', dob)
            param.append('age', age)
            param.append('edu', edu)
            param.append('shoe_size', shoeSize)
            param.append('shirt_size', shirtSize)
            param.append('sales_type', salesType)
            param.append('user', userProfile.profile.id_user)

            axios({
                method: "POST",
                url: `${host}/sales/index.php?action=CreateNewAgent`,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                data:param
            }). then (res => {
                if (res.data.status === 200){
                    Toaster.fire({
                        icon:"success",
                        text:"Success create new Agent",
                        position:'top-right'
                    })
                    getSalesAgentData()
                    setModalAddNew(false)
                    clearForm()
                } else {
                    Toaster.fire({
                        icon:"error",
                        text:res.data.msg,
                        position:'top-right'
                    })
                }
            })
        } else {
            Toaster.fire({
                icon:'warning',
                text:'Too young to be Agent',
                position:'top-right'
            })
        }
    }

    const getSalesAgentData = async () => {
        await axios({
            method: "GET",
            url: `${host}/sales/index.php?action=SalesAgent`,
            headers: {
                "Content-Type": "application/json",
            },
        }).then (res => {
            if (res.data.status === 200){
                setDataAgent(res.data.data)
            }
        })
    }

    const deleteAgent = async (id) => {
        const msg = window.confirm("Are you sure want to delete this data?")
        if (msg) {
            await axios({
                method: "DELETE",
                url: `${host}/sales/index.php?action=DeleteAgent&id=${id}`,
                headers: {
                    "Content-Type": "application/json",
                },
            }).then (res => {
                if (res.data.status === 200){
                    Toaster.fire({
                        icon:'success',
                        text:'Success delete Sales Agent',
                        position:'top-right',
                    })
                    getSalesAgentData()
                } else {
                    Toaster.fire({
                        icon:'error',
                        text:res.data.msg,
                        position:'top-right',
                    })
                }
            })
        }
    }
    
    useEffect(() => {
        const birth = moment(dob)
        const now = moment(currentDate)
        const diffDay = now.diff(birth, 'years')
        setAge(diffDay)
    }, [dob])

    useEffect(() => {
        getSalesAgentData()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Sales Agent</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={8} lg={10} md={10} sm={12} className="mb-3">
                        <Button content="New Sales Agent" icon="add" onClick={handleClickAddNew} color="blue"/>
                    </Col>
                    <Col xl={8} lg={10} md={10} sm={12}>
                        <Reacttable data={dataAgent} columns={columnsAgent} pageRow={10} noData={dataAgent.length === 0 ? true:false}/>
                    </Col>
                </Row>
            </Container>

            <Modal open={modalAddNew} onClose={()=>setModalAddNew(false)} overflow={false} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Add New Sales Agent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmAdd" onSubmit={handleSubmitAdd}>
                        <Form.Group>
                            <Form.Dropdown 
                                width={6}
                                clearable
                                options={showroomOpt}
                                selection
                                search={true}
                                label="Showroom"
                                value={showroom}
                                onChange={(e,{value}) => setShowroom(value)}
                            />
                            <Form.Input type="text" width={10} label="Complete Name" value={name} onChange={(e) => setName(e.target.value)} required/>
                        </Form.Group>
                        <Form.Input type="text" label="Address Detail" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Ex : Jl.Surapati No.61 Sadang Serang, Coblong, Bandung"/>
                        <Form.Group>
                            <Form.Dropdown
                                clearable
                                options={maritalOpt}
                                selection={true}
                                label="Marital Status"
                                value={marit}
                                onChange={(e,{value}) => setMarit(value)}
                            />
                            <Form.Input type="date" label="DOB" value={dob} onChange={(e) => setDob(e.target.value)} width={7}/>
                            <Form.Input type="number" label="Age" value={age} onChange={(e) => setAge(e.target.value)} width={4} readOnly />
                        </Form.Group>
                        <Form.Group>
                            <Form.Dropdown
                                clearable
                                width={7}
                                compact
                                options={lastEdu}
                                selection={true}
                                label="Last Education"
                                value={edu}
                                onChange={(e,{value}) => setEdu(value)}
                            />
                            <Form.Dropdown
                                clearable
                                width={4}
                                compact
                                options={shoeSizeOpt}
                                value={shoeSize}
                                selection={true}
                                label="Shoe Size (EU)"
                                onChange={(e, {value}) => setShoeSize(value)}
                            />
                            <Form.Dropdown
                                clearable
                                width={5}
                                compact
                                options={shirtSizeOpt}
                                selection={true}
                                label="Shirt Size"
                                value={shirtSize}
                                onChange={(e,{value}) => setShirtSize(value)}
                            />
                        </Form.Group>
                        <Form.Group inline>
                            <label>Sales Type :</label><br/>
                            <FormRadio
                                label='Offline Store'
                                value='offstore'
                                checked={salesType === 'offstore'}
                                onChange={(e, {value}) => setSalesType(value)}
                            />
                            <FormRadio
                                label='Market Place'
                                value='marketplace'
                                checked={salesType === 'marketplace'}
                                onChange={(e, {value}) => setSalesType(value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Create" icon="arrow alternate circle right outline" form="frmAdd" labelPosition="right" color="blue"/>
                </Modal.Footer>
            </Modal>

            <Modal open={modalEdit} onClose={()=>setModalEdit(false)} overflow={false} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Edit Sales Agent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmEdit" onSubmit={handleSubmitEdit}>
                        <Form.Group>
                            <Form.Dropdown 
                                width={6}
                                clearable
                                options={showroomOpt}
                                selection
                                search={true}
                                label="Showroom"
                                value={showroom}
                                onChange={(e,{value}) => setShowroom(value)}
                            />
                            <Form.Input type="text" width={10} label="Complete Name" value={name} onChange={(e) => setName(e.target.value)} required/>
                        </Form.Group>
                        <Form.Input type="text" label="Address Detail" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Ex : Jl.Surapati No.61 Sadang Serang, Coblong, Bandung"/>
                        <Form.Group>
                            <Form.Dropdown
                                clearable
                                options={maritalOpt}
                                selection={true}
                                label="Marital Status"
                                value={marit}
                                onChange={(e,{value}) => setMarit(value)}
                            />
                            <Form.Input type="date" label="DOB" value={dob} onChange={(e) => setDob(e.target.value)} width={7}/>
                            <Form.Input type="number" label="Age" value={age} onChange={(e) => setAge(e.target.value)} width={4} readOnly />
                        </Form.Group>
                        <Form.Group>
                            <Form.Dropdown
                                clearable
                                width={7}
                                compact
                                options={lastEdu}
                                selection={true}
                                label="Last Education"
                                value={edu}
                                onChange={(e,{value}) => setEdu(value)}
                            />
                            <Form.Dropdown
                                clearable
                                width={4}
                                compact
                                options={shoeSizeOpt}
                                value={shoeSize}
                                selection={true}
                                label="Shoe Size (EU)"
                                onChange={(e, {value}) => setShoeSize(value)}
                            />
                            <Form.Dropdown
                                clearable
                                width={5}
                                compact
                                options={shirtSizeOpt}
                                selection={true}
                                label="Shirt Size"
                                value={shirtSize}
                                onChange={(e,{value}) => setShirtSize(value)}
                            />
                        </Form.Group>
                        <Form.Group inline>
                            <label>Sales Type :</label><br/>
                            <FormRadio
                                label='Offline Store'
                                value='offstore'
                                checked={salesType === 'offstore'}
                                onChange={(e, {value}) => setSalesType(value)}
                            />
                            <FormRadio
                                label='Market Place'
                                value='marketplace'
                                checked={salesType === 'marketplace'}
                                onChange={(e, {value}) => setSalesType(value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Save Change" icon="save" form="frmEdit" labelPosition="right" color="blue"/>
                </Modal.Footer>
            </Modal>

        </>
    )
}
